import Link from 'next/link';

import { toTimeAgo } from '@/lib/dateTime';

import * as Styled from './styled';

import { IRepository } from '@/types';
import * as Icon from '@/ui/Icon';
import Tag from '@/ui/Tag';
import { Stack, Row } from '@/ui/primatives';

import { RepositoryDataTypesWithSize } from '@/components/Repository/RepositoryDataTypes';
import RepositoryCategories from '@/components/Repository/RepositoryCategories';
import RepositoryStarControl from '@/components/Repository/RepositoryStarControl';
import { RepositoryDataTypesChart } from '@/components/Repository/RepositoryDataTypes';

const ProgressGraph: React.FC<{ repository: IRepository }> = ({ repository }) => {
  return (
    <Styled.ProgressGraph>
      <div>Sparkline</div>
    </Styled.ProgressGraph>
  );
};

const RepositoryName: React.FC<{ showNamespace: boolean; repository: IRepository }> = ({
  showNamespace,
  repository,
}) => (
  <Row gap={10} alignItems="center" justifyContent="flex-start">
    <h6 className="block">
      {showNamespace && (
        <>
          <Link
            className="hover-link"
            href={`/${repository.namespace}`}
            data-ph-capture-attribute-resource-type="namespace"
          >
            {repository.namespace}
          </Link>
          /
        </>
      )}
      <Link
        className="hover-link"
        href={`/${repository.namespace}/${repository.name}`}
        data-ph-capture-attribute-resource-type="repository"
      >
        {repository.name}
      </Link>
    </h6>
  </Row>
);

const RepositoryUpdated: React.FC<{ repository: IRepository }> = ({ repository }) => (
  <div>
    {repository.latest_commit ? (
      <Row gap={10} alignItems="center" justifyContent="flex-start">
        <Icon.Clock size="1em" />
        <div className="timestamp">{`Updated: ${toTimeAgo(repository.latest_commit.timestamp)}`}</div>
      </Row>
    ) : (
      <div className="timestamp" onClick={() => console.log('repo', repository)}>
        Empty Repository
      </div>
    )}
  </div>
);

const RepositoryActions: React.FC<{ repository: IRepository; additionalActions?: React.ReactNode }> = ({
  repository,
  additionalActions,
}) => {
  return (
    <div>
      <Row gap={0} alignItems="center" justifyContent="flex-start">
        <Tag small variant="transparent" title="Total downloads">
          <Icon.Download />
          <span>{repository.metrics.clone_count}</span>
        </Tag>
        <Tag small variant="transparent" title="Total stars">
          <RepositoryStarControl repository={repository} simple />
        </Tag>
        {additionalActions}
      </Row>
    </div>
  );
};

const ForkedFromLink: React.FC<{ repository: IRepository }> = ({ repository }) => {
  return repository.forked_from ? (
    <small>
      forked from{' '}
      <Link href={`/${repository.forked_from.namespace}/${repository.forked_from.name}`} className="link-underline">
        {repository.forked_from.namespace}/{repository.forked_from.name}
      </Link>
    </small>
  ) : null;
};

const RepositoryHeader: React.FC<{
  showNamespace: boolean;
  repository: IRepository;
  hidePublicPrivateIndicator?: boolean;
  additionalActions?: React.ReactNode;
}> = ({ showNamespace, repository, hidePublicPrivateIndicator = false, additionalActions }) => (
  <Stack gap={5}>
    <Row gap={20} justifyContent="space-between" className="repo-card-row">
      <Stack gap={5}>
        <Row gap={10} alignItems="center">
          <RepositoryName showNamespace={showNamespace} repository={repository} />
          {!hidePublicPrivateIndicator && (
            <div>
              <Tag pill variant="white" small border>
                {repository.is_public ? 'Public' : 'Private'}
              </Tag>
            </div>
          )}
        </Row>
        <ForkedFromLink repository={repository} />
      </Stack>
      <RepositoryActions repository={repository} additionalActions={additionalActions} />
    </Row>
  </Stack>
);

const RepositoryCard: React.FC<{
  repository: IRepository;
  showNamespace?: boolean;
  hidePublicPrivateIndicator?: boolean;
  additionalActions?: React.ReactNode;
}> = ({ repository, showNamespace = false, hidePublicPrivateIndicator, additionalActions }) => {
  return (
    <Styled.RepositoryCard>
      <Stack gap={15} justifyContent="space-between">
        <RepositoryHeader
          showNamespace={showNamespace}
          repository={repository}
          hidePublicPrivateIndicator={hidePublicPrivateIndicator}
          additionalActions={additionalActions}
        />
        <p>{repository.description}</p>
        <Stack gap={20}>
          <RepositoryCategories repository={repository} />
          <Row gap={20} justifyContent="space-between" className="repo-card-row" alignItems="center">
            <RepositoryDataTypesWithSize repository={repository} sizeInBytes={repository.size} />
            <RepositoryUpdated repository={repository} />
          </Row>
          {/* <RepositoryDataTypesChart repository={repository} /> */}
        </Stack>
      </Stack>
    </Styled.RepositoryCard>
  );
};

export default RepositoryCard;
