import React from 'react';

import * as Styled from './styled';

interface CardlistProps extends React.HTMLAttributes<HTMLDivElement> {
  compressed?: boolean;
  children: React.ReactNode;
}

const Cardlist: React.FC<CardlistProps> = ({ children, compressed = false, ...props }) => {
  return (
    <Styled.Cardlist $compressed={compressed} {...props}>
      {children}
    </Styled.Cardlist>
  );
};

export default Cardlist;
